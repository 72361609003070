<template>
  <v-card height="100vh" elevation="0">
    <v-card-text class="d-flex justify-center">
      <v-card
        height="100vh"
        elevation="0"
        class="d-flex flex-column justify-center"
      >
        <v-card-text>
          <p class="ml-n3">Signing in</p>
          <v-progress-circular indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EsriRedirect",
  beforeMount() {
    const hashString = this.$route.hash.slice(1);
    const queryString = new URLSearchParams(hashString);
    const obj = Object.fromEntries([...queryString]);
    window.opener.postMessage(obj);
    window.opener.focus();
  },
};
</script>
